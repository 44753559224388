// Pulse 5.2.2
// Bootswatch

$theme: "pulse" !default;

//
// Color system
//

$white: #ffffff !default;
$gray-100: #fafafa !default;
$gray-200: #f9f8fc !default;
$gray-250: #f3f3f5 !default;
$gray-300: #ededed !default;
$gray-400: #cbc8d0 !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #444 !default;
$gray-800: #343a40 !default;
$gray-900: #17141f !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #593196 !default;
$pink: #e83e8c !default;
$red: #fc3939 !default;
$orange: #fd7e14 !default;
$yellow: #efa31d !default;
$green: #13b955 !default;
$teal: #20c997 !default;
$cyan: #009cdc !default;

// indi colors
$indi-primary: #ff4e00 !default;
$indi-primary-hover: #df421b !default;

$indi-secondary: #02b901 !default;
$indi-secondary-hover: #09ce09 !default;

$indi-color-white: #ffffff !default;
$indi-color-grey-darker: #434b4d !default;
$indi-color-grey-dark: #8c8c8c !default;
$indi-color-grey-normal: #a6a5a6 !default;
$indi-color-grey-light: #f6f6f6 !default;
$indi-color-grey-lighter: #fbfbfb !default;
$indi-color-grey-border: #e0e0e0 !default;
$indi-color-grey-border-box: #bfbfbf !default;
$indi-color-paragraph: #9b9b9b !default;
$indi-color-placeholder: #9b9b9b !default;
$indi-color-black: #000000 !default;
$indi-color-header: $indi-color-black !default;
$indi-color-font: #454f51 !default;
$indi-color-selected-tag: #bbc !default;
$indi-color-white-transparent: #f0f0f0 !default;

$indi-color-green: #5ca420 !default;
$indi-color-green-highlight: #7cc440 !default;
$indi-color-green-light: #5ca420 !default;

$indi-color-gold: #ffd700 !default;

$indi-color-red: #ff0000 !default;

$indi-color-blue: #66ccff !default;

$indi-border-color: #e0e0e0 !default;

$primary: $indi-primary !default;
$secondary: $indi-secondary !default;
$success: $indi-color-green !default;
$info: $indi-color-blue !default;
$warning: $indi-color-gold !default;
$danger: $indi-color-red !default;
$light: $indi-color-grey-light !default;
$dark: $indi-color-grey-darker !default;

// colors for danger alerts
$danger-bg-subtle: inherit;
$danger-text-emphasis: $danger;
$danger-border-subtle: $danger;

$min-contrast-ratio: 2.1 !default;

// Options

$enable-rounded: false !default;

// Body

$body-color: $indi-color-grey-darker !default;

// Links

$link-hover-color: $primary !default;

// Tables

$table-border-color: rgba(0, 0, 0, 0.05) !default;

// Forms

$input-border-color: $indi-color-grey-normal;
$input-focus-border-color: $primary !default;

$form-check-input-border: 2px solid $indi-color-grey-normal;

// Dropdowns

$dropdown-link-color: $gray-700 !default;
$dropdown-link-hover-color: $white !default;
$dropdown-link-hover-bg: $primary !default;

// Navs

$nav-tabs-border-color: $indi-color-grey-border-box !default;
$nav-tabs-link-hover-border-color: $primary !default;

// Navbar

$navbar-padding-y: 1.2rem !default;
$navbar-light-color: rgba($black, 0.7) e !default;
$navbar-light-active-color: rgba($black, 0.7) !default;
$navbar-light-disabled-color: rgba($black, 0.2) !default;

$navbar-dark-color: rgba($indi-color-white, 0.7) !default;
$navbar-dark-hover-color: rgba($indi-color-white, 0.9) !default;
$navbar-dark-active-color: rgba($indi-color-white, 0.9) !default;
$navbar-dark-disabled-color: rgba($white, 0.25) !default;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color: rgba($white, 0.1) !default;
$navbar-dark-brand-color: $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color: $navbar-dark-active-color !default;
// scss-docs-end navbar-dark-variables

// Progress bars

$progress-bg: $gray-300 !default;
$progress-bar-bg: $primary !default;

// List group

$list-group-bg: $white !default;
$list-group-border-color: $indi-color-grey-border-box !default;
$list-group-hover-bg: $gray-300 !default;
$list-group-active-color: $white !default;
$list-group-active-bg: $list-group-bg !default;
$list-group-disabled-color: $gray-400 !default;

// Media break points
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// Z-indices

$zindex-dropdown: 2000 !default; // To counter sticky header z-index
